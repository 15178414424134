import React from 'react';

export default function ContactPage() {
  return (
    <div className="mx-auto w-full z-10 sticky md:top-16 top-16  bg-gray-200">
      <div className="md:flex justify-between mx-auto w-full ">
        <div className=" mx-auto w-full  bg-gradient-to-r md:from-gray-100 md:via-gray-100 md:to-pink-100">
          <h2 className="text-sm md:text-lg font-sans text-gray-600 text-center">
            Contact For Booking or Enquiry About Services |
          </h2>
        </div>

        {/* <div className=" md:flex justify-between  mx-auto w-full md:bg-gradient-to-r md:from-pink-100 md:via-purple-100 md:to-indigo-100">
          <div className="w-full md:bg-gradient-to-r md:from-pink-100 md:via-indigo-100 md:to-indigo-100">
            <div className="min-h-full ">
              <div className="flex justify-center sm:flex sm:justify-center  md:flex md:justify-center py-1 min-h-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>

                <a
                  href="tel:7004339423"
                  className="text-sm font-semibold  text-black ml-2"
                >
                  7004339423
                </a>
                <a
                  href="tel:9122810408"
                  className="text-sm font-semibold  text-black ml-2"
                >
                  9122810408
                </a>
                <a
                  href="tel:8709852917"
                  className="text-sm font-semibold  text-black ml-2"
                >
                  8709852917
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
