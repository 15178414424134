import React from 'react';
import facebook from '../assests/facebook.webp';
import youtube from '../assests/youtube-logo-icon-transparent---32.png';
import instagram from '../assests/Beige Elegant Collage Store Opening Instagram Post.png';
export const Footer = () => {
  const y = new Date();
  let year = y.getFullYear();
  return (
    <footer className="bg-gray-900 dark:bg-gray-800">
      <div className="container px-6 py-8 mx-auto">
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <div>
            <div className="text-xs font-medium text-blue-200 uppercase">
              <p>About New Urban Service</p>
            </div>

            <a
              href={`/about_us`}
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
            >
              About us
            </a>
            <a
              href={`/term_and_condition`}
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
            >
              Terms & conditions
            </a>
            <a
              href={`/privacypolicy`}
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
            >
              Privacy policy
            </a>
            <a
              href={`/refundpolicy`}
              className="block mt-3 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
            >
              Refund Policy
            </a>
          </div>

          <div>
            <div className="text-xs font-medium text-blue-200 uppercase">
              Information
            </div>
            <a
              href={`/#`}
              className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-gray-300 ">Home</b>
            </a>
            <a
              href={`/mapform`}
              className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-gray-300 ">Contact us</b>
            </a>
            <a
              href={`/mapform`}
              className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-gray-300 ">Enquiry</b>
            </a>{' '}
            <a
              href={`/#`}
              className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-green-400 dark:hover:text-gray-200 hover:underline"
            >
              <b className="text-xs font-medium text-gray-300 ">Services</b>
            </a>
          </div>
          <div>
            <div className="text-xs font-medium text-blue-200 uppercase">
              Social Media
            </div>

            <p className="flex mt-3 ">
              <img src={facebook} alt="" className="h-5 w-6"></img>
              <a
                href="https://www.facebook.com/profile.php?id=61564972834140"
                rel="noreferrer"
                target={'_blank'}
                className="flex px-2 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
              >
                Facebook
              </a>
            </p>
            <p className="flex mt-2">
              <img src={instagram} alt="" className="h-5 w-6"></img>
              <a
                href="https://www.instagram.com/md_saquib_xyz/reel/DALTV-JNQoF/"
                rel="noreferrer"
                target={'_blank'}
                className="flex px-2 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
              >
                Instagram
              </a>
            </p>
            <p className="flex mt-2">
              <img src={youtube} alt="" className="h-5 w-6"></img>
              <a
                href="https://www.youtube.com/channel/UClFhZ2lQ3rp32nUWioaftEQ"
                rel="noreferrer"
                target={'_blank'}
                className="flex px-2 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
              >
                Youtube
              </a>
            </p>
          </div>
          <div>
            <div className="text-xs font-medium text-blue-200 uppercase">
              <p>Reach Us</p>
            </div>

            <a
              href={`/#`}
              className="block mt-5 text-sm font-medium text-gray-500 duration-700 dark:text-gray-300 hover:text-gray-400 dark:hover:text-gray-200 hover:underline"
            >
              Bhagalpur, Hatiya chawk near Madina Maszid 812001-
            </a>

            <div className="flex ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="gray"
                className="h-5 w-5 mt-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
                />
              </svg>

              <a
                href="mailto:support@newurbanservices.in"
                className="text-sm py-1 text-gray-500 ml-2"
              >
                support@newurbanservices.in
              </a>
            </div>
          </div>
        </div>
        <hr className="my-10 border-gray-200 dark:border-gray-400"></hr>
        <div className="sm:flex sm:items-center sm:justify-center flex items-start justify-center">
          <a
            href="https://dilshadazam.github.io/portfolio/"
            target="_blank"
            rel="noreferrer"
          >
            {' '}
            <p className="text-sm text-gray-400">
              Copyright ©Developer : {year}. All Rights Reserved.
            </p>
          </a>
        </div>
      </div>
    </footer>
    //sdfsdf
  );
};
